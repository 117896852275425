import React from 'react'
import { motion } from 'framer-motion'

function TextContent({ place_holder, rounded, border, onChangeHandler, text }) {

  return (
    <motion.textarea
      placeholder={place_holder}
      className={`w-100 h-100 border-top ${border} border-bottom-0 px-2 pt-3 ${rounded}`}
      rows="8"
      style={{ resize: "none" }}
      onChange={onChangeHandler}
      value={text}
      whileFocus={{ scale: 1.03 }}
    >
    </motion.textarea>
  )
}

export default TextContent