import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
// import LanguageSelection from '../components/common/LanguageSelection';
import TextContent from '../components/common/TextContent';
// import { AuthContext } from '../components/AuthProvider';
import axios from 'axios';
import { motion } from 'framer-motion';

function Traduction() {
  const [textValue, setTextValue] = useState("");
  const [traductionValue, setTraductionValue] = useState("");
  const [token, setToken] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;
  const PSEUDO = process.env.REACT_APP_PSEUDO;
  const PASS = process.env.REACT_APP_PASS;

  useEffect( () => {
    const handleLogin = async () => {
      try {
        const requestBody = {
          "pseudo": PSEUDO,
          "password": PASS 
        };  
        const response = await axios.post(`${API_URL}/login`, requestBody, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        const data = response.data;
        // Handle response data
        setToken(data["access token"]);
        // Alert response data
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle 403 Forbidden error
          alert("Not authenticated");
        } else {
          // Handle other errors
          console.error('There was a problem with your fetch operation:', error);
        }
      }
    };
    handleLogin();
  },[API_URL, PSEUDO, PASS])
  

  const translateHandler = async () => {
    try {
      const requestBody = {
        'sentence': textValue // Assuming textValue is defined elsewhere
      };

      const response = await axios.post(`${API_URL}/traduction_tal/traduire?path=traduire_sentence`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const data = response.data;
      // Handle response data
      setTraductionValue(JSON.stringify(data));
      // Alert response data
      // save translation
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Handle 403 Forbidden error
        alert("Not authenticated");
      } else {
        // Handle other errors
        console.error('There was a problem with your fetch operation:', error);
      }
    }
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
    if (traductionValue !== "") {
      setTraductionValue("");
    }
  };

  return (
    <Row className=''>
      <motion.div
        className='p-5'
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ delay: 0.2, type: "spring", stiffness: 37 }}
      >
        <h1 className='text-primary fw-bold fs-3'>Traducteur Ndovoo</h1>
        <span className='fw-bold'>
          Notre traducteur supporte plusieurs langues. Nous avons réussi grâce à l'intelligence artificielle
          à mettre en place la traduction anglaise vers le shimaore. D'autres langues seront disponibles
          prochainement notamment le français, le swahili et l'allemand.
        </span>
      </motion.div>
      <motion.div
        className=''
        initial={{ opacity: 0.0 }}
        animate={{ opacity: 1.0 }}
        transition={{ delay: 0.9, duration: 1.2 }}
      >
        <Container
          className='p-5 w-100'
        >
          <Row className='bg-light shadow rounded'>
            <Row className='mx-auto p-4'>
              <Stack direction='horizontal' gap={5} className='justify-content-evenly'>
                {/* <LanguageSelection default_value={"fra"} />
                <LanguageSelection default_value={"swb"} /> */}
                <span className='bg-light border-light fw-bold w-25'>Français</span>
                <span className='bg-light border-light fw-bold w-25'>Shimaore</span>
              </Stack>
            </Row>
            <Row className='p-0 m-0 w-100'>
              <Col className='p-0 m-0 w-100 h-100'>
                <TextContent
                  place_holder={"Entrez ou collez votre texte ici"}
                  // rounded={"rounded-bottom"}
                  border={"border-start-0"}
                  onChangeHandler={handleTextChange}
                  text={textValue}
                />
              </Col>
              <Col className='p-0 m-0 w-100 h-100'>
                <TextContent
                  // rounded={"rounded-bottom"}
                  border={"border-start-0 border-end-0"}
                  text={traductionValue}
                />
              </Col>
            </Row>
            <Row className='p-0 m-0 w-100'>
              <motion.button
                className='p-3 w-100 h-100 bg-secondary border-0 shadow rounded-bottom fw-bold fs-5 text-light'
                onClick={translateHandler}
                whileTap={{ scale: 1.03 }}
              >
                Traduire
              </motion.button>
            </Row>
          </Row>
        </Container>

      </motion.div>

    </Row>
  );
}

export default Traduction;
