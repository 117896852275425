import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../assets/images/ndovoo_logo.png"
// import { AuthContext } from '../AuthProvider';
import { motion } from 'framer-motion';

function MainMenu({ onClickHandler }) {
    // const { logout } = useContext(AuthContext);

    // const handleLogOut = async (e) => {
    //     e.preventDefault();
    //     logout()
    // }
    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary shadow-sm">
            <Container className=''>
                <Navbar.Brand href="#" className="fw-bold fs-4">
                    <motion.img
                        src={logo}
                        alt="Ndovoo"
                        className='img-fuild rounded-pill'
                        style={{
                            maxWidth: '75px',
                            maxHeight: '75px',
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px"
                        }}
                        initial={{ opacity: 0.0 }}
                        animate={{ opacity: 1.0 }}
                        transition={{ delay: 0.9, duration: 1.2 }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className=''>
                    <Nav className="mx-auto fw-bold fs-5 w-75 d-flex justify-content-start">
                        {/* <Nav.Link href="#" className='' onClick={() => onClickHandler("Acceuil")}>
                            <motion.span
                                initial={{ opacity: 0.0 }}
                                animate={{ opacity: 1.0 }}
                                transition={{ delay: 0.9, duration: 1.2 }}
                            >
                                Accueil
                            </motion.span>
                        </Nav.Link> */}
                        <Nav.Link href="#" onClick={() => onClickHandler("Traduction")}>
                            <motion.span
                                initial={{ opacity: 0.0 }}
                                animate={{ opacity: 1.0 }}
                                transition={{ delay: 0.9, duration: 1.2 }}
                            >
                                Traduction
                            </motion.span>
                        </Nav.Link>
                        {/* <Nav.Link href="#" onClick={() => onClickHandler("Corpus")}>
                            <motion.span
                                initial={{ opacity: 0.0 }}
                                animate={{ opacity: 1.0 }}
                                transition={{ delay: 0.9, duration: 1.2 }}
                            >
                                Corpus
                            </motion.span>
                        </Nav.Link> */}
                    </Nav>
                    {/* <Nav className="fw-bold fs-5">
                        <motion.button
                            className='px-4 py-1 bg-secondary rounded-5 text-white fw-bold border-0'
                            onClick={handleLogOut}
                            initial={{ opacity: 0.0 }}
                            animate={{ opacity: 1.0 }}
                            transition={{ delay: 0.9, duration: 1.2 }}
                            whileTap={{ scale: 1.03 }}
                            style={{"box-shadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px;"}}
                        >
                            Déconnexion
                        </motion.button>
                    </Nav> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MainMenu