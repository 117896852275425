import React from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import MainBtn from '../components/MainBtn'
import translator from "../assets/images/translator.png"
import corpus from "../assets/images/corpus.png"
import { motion } from 'framer-motion'


function Acceuil({ user_name, onClickHandler }) {
  const onMainBtnClick = async (value) => {
    onClickHandler(value)
  };

  return (
    <Col className=''>
      <Container className=''  >
        <Row className=''>
          <motion.div
            initial={{ x: "-100vw" }}
            animate={{ x: 0 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 50 }}
            className='p-5'
          >
            <motion.h1

              className='text-secondary fw-bold fs-3'
            >
              Bonjour, {user_name}
            </motion.h1>
            <span className='fw-bold'>que souhaitez-vous faire aujourd'hui ?</span>
          </motion.div>
        </Row>
        <Row>
          <Stack direction='horizontal' gap={5} className='justify-content-center' >
            <MainBtn
              btn_image={translator}
              btn_text={"Traduire une phrase"}
              onMainBtnClick={onMainBtnClick}
            />
            <MainBtn
              btn_image={corpus}
              btn_text={"Contribuer au Corpus"}
              onMainBtnClick={onMainBtnClick}
            />
          </Stack>
        </Row>
      </Container>
    </Col>
  )
}

export default Acceuil