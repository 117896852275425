import React from 'react'
import { motion } from 'framer-motion'

function MainBtn({ btn_image, btn_text, onMainBtnClick }) {
  return (
    <motion.div
      className='d-flex flex-column align-items-center p-3 main-btn'
      onClick={() => onMainBtnClick(btn_text)}
      initial={{ opacity: 0.0 }}
      animate={{ opacity: 1.0 }}
      transition={{ delay: 0.9, duration: 1.2 }}
    >
      <div>
        <img
          src={btn_image}
          width={200}
          height={200}
          alt={btn_text}
        />
      </div>
      <div className='fw-bold mt-4'>
        {btn_text}
      </div>
    </motion.div>
  )
}

export default MainBtn