import React from 'react'
import Form from 'react-bootstrap/Form';


function LanguageSelection({default_value}) {
  return (
    <Form.Select aria-label="Language selection" className='bg-light border-light fw-bold w-25' defaultValue={default_value}>
      <option value="swa" className='fw-bold'>Swahili</option>
      <option value="swb" className='fw-bold'>Shimaore</option>
      <option value="fra" className='fw-bold'>Français</option>
      <option value="eng" className='fw-bold'>Anglais</option>
    </Form.Select>
  )
}

export default LanguageSelection