import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

function Mention({ user_name, onClickHandler }) {
    return (
        <Container>
            <Row className='mt-5'>
                <Col className='text-center fw-bold'>
                    <h1>Mentions légales</h1>
                    <span>En vigueur au 30/04/2024</span>
                </Col>
            </Row>
            <Row className='mt-5 px-5'>
                <Col className=''>
                    <h3 className="fw-bold">Edition</h3>
                    <div class="border-bottom border-primary"></div>
                    <p className='fs-5 mx-5 mt-4'>
                        Ndovoo<br />
                        <br />Société par Actions Simplifiée Unipersonnelle au capital de 100 €
                        <br />CCI Mayotte, Place mariage CS 73904
                        <br />97641 Mamoudzou Cedex
                        <br /><br />RCS : Mamoudzou B 897 798 120
                        <br />N° TVA : FR28897798120
                        <br />APE : 6202A Conseil en systèmes et logiciels informatiques
                        <br />Mailto : nourdine.combo@ndovoo.com
                        <br /><br />Directeur de la publication : Nourdine Combo
                    </p>
                </Col>
            </Row>
            <Row className='mt-5 px-5'>
                <Col className=''>
                    <h3 className="fw-bold">Conception</h3>
                    <div class="border-bottom border-primary"></div>
                    <p className='fs-5 mx-5 mt-4'>
                        le coltineur lorrain<br />
                        <br />Société par Actions Simplifiée Unipersonnelle au capital de 2500 €
                        <br />19 rue Victor Hugo
                        <br />54390 Frouard
                        <br /><br />RCS : Nancy B 913 417 689
                        <br />N° TVA : FR72913417689
                        <br />APE : 6202A Conseil en systèmes et logiciels informatiques
                        <br />Site : <a href='https://jmdidier.fr/'>www.lecoltineurlorrain.com</a>
                        <br />Mailto : jmdidier@lecoltineurlorrain.com
                    </p>
                </Col>
            </Row>
            <Row className='mt-5 px-5'>
                <Col className=''>
                    <h3 className="fw-bold">Hébergement</h3>
                    <div class="border-bottom border-primary"></div>
                    <p className='fs-5 mx-5 mt-4'>
                        L&#39;hébergeur du site www.ndovoo.com est la Société FIREBASE HOSTING par GOOGLE, dont le siège social est situé au Google LLC, 1600 Amphitheatre
                        Parkway, Mountain View, California 94043 USA. (mettre l’hyperlien ?)
                    </p>
                </Col>
            </Row>
            <Row className='mt-5 px-5'>
                <Col className=''>
                    <h3 className="fw-bold">Données personnelles</h3>
                    <div class="border-bottom border-primary"></div>
                    <p className='fs-5 mx-5 mt-4'>
                        Le site www.ndovoo.com est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte
                        aucune donnée concernant les utilisateurs.
                    </p>
                </Col>
            </Row>
            <Row className='mt-5 px-5'>
                <Col className=''>
                    <h3 className="fw-bold">Propriété intellectuelle</h3>
                    <div class="border-bottom border-primary"></div>
                    <ul className='fs-5 mx-5 mt-4'>
                        <li>logo Ndovoo : conception xxxxxxxx</li>
                        <li>le coltineur lorrain : marque déposée – INPI – N° National : 20 4 709 248</li>
                        <li>logo le coltineur lorrain : conception Eli Vercollier</li>
                        <li>images : conception le coltineur lorrain, utilisation du logiciel Canva</li>
                    </ul>
                    <p className='fs-5 mx-5 mt-4'>
                        Nous vous rappelons que toute copie, même partielle, de quelques éléments que ce soit du site www.ndovoo.com est interdite. Le non-respect de
                        cette interdiction constitue une contrefaçon susceptible d’engager la responsabilité civile et pénale du contrefacteur.
                    </p>
                </Col>
            </Row>
            <Row className='mt-5 px-5'>
                <Col className=''>
                    <h3 className="fw-bold">Conditions générales de vente</h3>
                    <div class="border-bottom border-primary"></div>
                    <p className='fs-5 mx-5 mt-4'>
                        Le site www.ndovoo.com n’est pas un site marchand et ne propose pas de vente en ligne.
                    </p>
                </Col>
            </Row>
            <Row className='mt-5 px-5'>
                <Col className=''>
                    <h3 className="fw-bold">Accès au site</h3>
                    <div class="border-bottom border-primary"></div>
                    <p className='fs-5 mx-5 mt-4'>
                        Le site www.ndovoo.com est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant
                        découlant d’une nécessité de maintenance. En cas de modification, interruption ou suspension des services, le site www.ndovoo.com ne saurait être
                        tenu responsable.
                    </p>
                </Col>
            </Row>
            <Row className='mt-5 px-5'>
                <Col className=''>
                    <h3 className="fw-bold">Cookies</h3>
                    <div class="border-bottom border-primary"></div>
                    <p className='fs-5 mx-5 mt-4'>
                        Un cookie est un traceur permettant d’analyser le comportement des internautes, comme leurs navigations, leurs habitudes de consommation, leurs
                        déplacements, etc. Le site www.ndovoo.com n’utilise pas de cookies.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Mention