import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './custom.sass';
import Dashboard from './routes/Dashboard';
// import Login from "./routes/Login"
import { AuthProvider } from './components/AuthProvider';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// import PrivateRoutes from './components/PrivateRoutes';


const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <Router>
          <Routes>
            {/* <Route path="/" element={<Login />}/> */}
            {/* <Route element={<PrivateRoutes />}> */}
              <Route element={<Dashboard />} path='/' exact />
            {/* </Route> */}
          </Routes>         
        </Router>
      </AuthProvider>
    </React.StrictMode>
  );
}

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();