import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [token, setToken] = useState("")

  const login = (token) => {
    // Call authentication API, set isLoggedIn to true upon successful authentication
    setToken(token)
    setIsLoggedIn(true);
  };

  const logout = () => {
    // Clear authentication tokens, set isLoggedIn to false
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
