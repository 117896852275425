import React from 'react';
import { motion } from 'framer-motion';
function Footer({ onClickHandler }) {
    return (
        <motion.footer
            className='shadow-lg text-center'
            initial={{ opacity: 0.0 }}
            animate={{ opacity: 1.0 }}
            transition={{ delay: 0.9, duration: 1.2 }}
        >
            <div className='container p-2'>
                <section className="mb-4">
                    <ul className='list-unstyled'>
                        <a
                            className="text-dark m-4 text-decoration-none"
                            href="#!"
                            role="button"
                            onClick={() => onClickHandler("Mention")}
                        >
                            MENTIONS LÉGALES
                        </a>
                    </ul>

                </section>
                <section className="mb-3">
                    <div>
                        <span className='m-2'>Powered By </span>
                        <span
                            className='text-decoration-none text-primary fw-bold'>
                            Ndovoo
                        </span>
                    </div>
                </section>
            </div>
        </motion.footer>
    )
}

export default Footer