import React, { useState, useContext } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import LanguageSelection from '../components/common/LanguageSelection';
import TextContent from '../components/common/TextContent';
import { AuthContext } from '../components/AuthProvider';
import axios from 'axios';
import { motion } from 'framer-motion';

function Corpus() {
  const { token } = useContext(AuthContext);
  const [proposedSentence, setProposedSentence] = useState("")
  const API_URL = process.env.REACT_APP_API_URL;

  const handleProposer = async () => {
    try {
      const requestParams = {
        params: {
          source_language: "swa", // Add source language value
          target_language: "swb" // Add target language value
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };

      const response = await axios.get(`${API_URL}/data/get_sentence_by_lang`, requestParams);

      const data = response.data;
      // Handle response data
      setProposedSentence(data["swa"])
      // Alert response data
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Handle 403 Forbidden error
        alert(error.response["data"]["detail"]);
      } else if (error.response && error.response.status === 422) {
        alert(error.response["data"]["detail"]);
      } else {
        // Handle other errors
        console.error('There was a problem with your fetch operation:', error);
      }
    }
  }
  return (
    <Row className=''>
      <motion.div
        className='p-5'
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ delay: 0.2, type: "spring", stiffness: 37 }}
      >
        <h1 className='text-primary fw-bold fs-3'>Corpus</h1>
        <span className='fw-bold'>Afin d'améliorer les performance des modèles</span>
      </motion.div>
      <motion.div
        initial={{ opacity: 0.0 }}
        animate={{ opacity: 1.0 }}
        transition={{ delay: 0.9, duration: 1.2 }}
      >
        <Container className='px-5'>
          <Row className=''>
            <Stack direction='horizontal' gap={5} className=''>
              <span>Choisissez la langue source :</span>
              <LanguageSelection default_value={"fra"} />
            </Stack>
          </Row>
          <Row className='p-0 m-0 w-100'>
            <motion.button
              className='my-4 p-3 w-25 h-100 bg-secondary text-light border-0 shadow rounded fw-bold fs-5'
              onClick={handleProposer}
              whileTap={{ scale: 1.03 }}
            >
              Proposer
            </motion.button>
          </Row>
          <Row className='bg-light shadow-sm rounded mb-4 p-3 fw-bold'>
            <Col className='p-0 m-0 w-100 h-100'>
              {proposedSentence !== "" && <span>{proposedSentence}</span>}
            </Col>
          </Row>
          <Row className='bg-light shadow rounded'>
            <Row className='p-0 m-0 w-100'>
              <Col className='p-0 m-0 w-100 h-100'>
                <TextContent
                  place_holder={"Proposer une traduction en Shimaore"}
                  rounded={"rounded"}
                  border={"border-0"}
                />
              </Col>
            </Row>
          </Row>
          <Row className='p-0 m-0 w-100'>
            <motion.button
              className='my-5 p-3 w-25 h-100 bg-secondary text-light border-0 shadow rounded fw-bold fs-5'
              whileTap={{ scale: 1.03 }}
            >
              Valider
            </motion.button>
          </Row>
        </Container>
      </motion.div>
    </Row>
  )
}

export default Corpus