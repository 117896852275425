import React, { useState } from 'react';
import Acceuil from '../containers/Acceuil';
import MainMenu from '../components/common/MainMenu';
import { Container, Row } from 'react-bootstrap';
import Traduction from '../containers/Traduction';
import Corpus from '../containers/Corpus';
import Mention from '../containers/Mention';
import Footer from '../components/common/Footer';

function Dashboard() {
    const [currentMenu, setCurrentMenu] = useState("Traduction");

    const onClickHandler = async (value) => {
        setCurrentMenu(value);
    };

    let contentToShow;

    if (currentMenu === "Acceuil") {
        contentToShow = <Acceuil user_name={"ilias"} onClickHandler={onClickHandler} />;
    } else if (currentMenu === "Traduction" | currentMenu === "Traduire une phrase") {
        contentToShow = <Traduction />;
    } else if (currentMenu === "Corpus" | currentMenu === "Contribuer au Corpus") {
        contentToShow = <Corpus />;
    } else if (currentMenu === "Mention") {
        contentToShow = <Mention />;
    }
    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Container style={{ flex: '1' }} fluid>
                <Row>
                    <MainMenu onClickHandler={onClickHandler} />
                </Row>
                <Row>
                    {contentToShow}
                </Row>
            </Container>
            <Footer onClickHandler={onClickHandler}/>
        </div>
    )
}

export default Dashboard